<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="stepbox four">
          <div class="line"></div>
          <div class="now"></div>
          <div class="step stepone ok">
            <span><i class="icon-stepone"></i></span>
            <p>1、选择票种</p>
          </div>
          <div class="step steptwo f_s ok">
            <span><i class="icon-steptwo"></i></span>
            <p>2、填写游客信息</p>
          </div>
          <div class="step stepthree f_s ok">
            <span><i class="icon-stepthree"></i></span>
            <p>3、确认支付</p>
          </div>
          <div class="step five f_s">
            <span><i class="icon-stepfive"></i></span>
            <p>4、购票成功</p>
          </div>
        </div>
        <div class="box ticket">
          <div class="title"><span>预订票务信息</span></div>
          <div class="content">
            <!-- <p class="nodata"><img src="images/loading.gif" alt="数据加载中" title="数据加载中" /></p> -->
            <div class="databox">
              <div class="ticketstyle">
                <div class="total">票数小计<span>{{payQpInfo.reqOrderGroupDetails.length}}张</span>小计<em>￥{{payQpInfo.totalPrice}}</em></div>
                <div class="name">票务总计</div>
              </div>
              <div class="ticketinfo">
                <table>
                  <thead>
                  <tr class="gray">
                    <th>商品名称</th>
                    <th>单价</th>
                    <th>预订数量</th>
                    <th>总价</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(itm,idx) in goodsInfo" :key="idx" :class="idx%2 == 1 ? 'gray' : ''">
                    <td>{{itm.groupName}}</td>
                    <td>￥{{itm.sellPrice ? Number(itm.sellPrice/100).toFixed(2) : 0}}</td>
                    <td>{{itm.totalNum}}</td>
                    <td>￥{{itm.sellPrice ? Number(itm.sellPrice/100*itm.totalNum).toFixed(2) : 0}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="box order">
          <div class="title"><span>行程单</span></div>
          <div class="content">
            <div class="detail">
              <div class="item">
                <div class="itemname">预计消费日期</div>
                <div class="iteminfo">{{payQpInfo.bookUseDate ? payQpInfo.bookUseDate.split(' ')[0] : '--'}}</div>
              </div>
              <div class="item">
                <div class="itemname">入园时段</div>
                <div class="iteminfo"><span v-if="payQpInfo.reqOrderGroupDetails.length">{{showTimeInTo}}</span></div>
              </div>
              <div class="item">
                <div class="itemname">预订人姓名</div>
                <div class="iteminfo">{{payQpInfo.reservationPersonName}}</div>
              </div>
              <div class="item">
                <div class="itemname">预订人电话</div>
                <div class="iteminfo">{{payQpInfo.reservationPersonTelephone}}</div>
              </div>
              <div class="item">
                <div class="itemname">票数总计</div>
                <div class="iteminfo">{{payQpInfo.reqOrderGroupDetails.length}}</div>
              </div>
              <div class="item">
                <div class="itemname">金额总计</div>
                <div class="iteminfo">{{payQpInfo.totalPrice}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box pay">
          <div class="title"><span>选择支付方式</span></div>
          <div class="content">
            <!--<div class="lefttime" v-if="time && time!='已超时，订单自动关闭'">
              <i class="lefttimelock"></i>请在<em>{{time}}</em>内完成支付，超时订单将自动关闭
            </div>
            <div class="lefttime" v-else><i class="lefttimelock"></i>{{time}}</div>-->
            <div class="paylist">
              <div class="payitem" v-for="(item,index) in payArr" :key="index" @click="payIndex = index">
                <label :class="payIndex == index ? 'checked' : ''">
                  <span><i></i></span>{{item.name}}<i><img :src="item.src" :alt="item.name" /></i>
                  <div class="leftmoney" v-if="item.name.indexOf('余额')> -1">可用余额：<em class="mr_50">{{balance ? Number(balance/100).toFixed(2) : 0}}</em></div>
                  <div class="leftmoney" v-if="item.name.indexOf('授信')> -1">可用授信余额：<em class="mr_50">{{creditBalance ? Number(creditBalance/100).toFixed(2) : 0}}</em></div>
                </label>
              </div>
            </div>
            <div class="btns">
              <div class="info">票数总计<span>{{payQpInfo.reqOrderGroupDetails.length}}张</span>金额总计<em>￥{{payQpInfo.totalPrice}}</em></div>
              <a href="javascript:;" @click="pay" title="确认支付">确认支付</a><a href="javascript:;" @click="cancel" title="取消" class="cancel">取消</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="systemTips" v-show="toPay">
      <div class="wrap">
        <a href="javascript:;" class="close" @click="toPay = false, paying = false">关闭</a>
        <div class="title">请使用微信扫码支付</div>
        <div class="content paybox">
          <!--<div class="lefttime"><i class="lefttimelock"></i>请在<em>{{time}}</em>内完成支付，超时订单将自动关闭</div>-->
          <div class="qrbox" ref="qrCodeUrl" id="qrcode"></div>
        </div>
        <div class="btns">
          <a href="javascript:;" @click="toPay = false, paying = false">修改支付方式</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import ye from '../assets/images/余额支付.png'
  import ky from '../assets/images/快赢聚合.png'
  import sx from '../assets/images/授信支付.png'
  export default {
    name: "fillIn",
    data() {
      return {
        // 显示支付二维码
        toPay: false,
        info:{order:{},orderDetails:[{}]},
        showArr:[],
        balance:null,
        interval:null,
        showTimeC:null,
        pTime:null,
        showTimeInTo:'',
        time:'',
        payArr:[
          {src:ky,"memberId":3803696,"enumId":3803684,"name":"快盈支付","value":"2","parentMemberId":0,"sequence":1,"version":5128982},
          {src:ye,"memberId":3803705,"enumId":3803684,"name":"余额支付","value":"4","parentMemberId":0,"sequence":2,"version":5128991},
          {src:sx,"memberId":3803710,"enumId":3803684,"name":"授信支付","value":"5","parentMemberId":0,"sequence":3,"version":5128996}],
        payIndex:-1,
        stuInterval:null,
        payQpInfo:{},
        saveInfo:{},
        orderNumber:'',
        paying: false, // 支付中
        goodsInfo:[],
      };
    },
    created(){
      this.getInfo();
      // this.queryDisPayType();
      this.getBusinessWallet();
      this.goodsInfo = sessionStorage.getItem('goodsInfo') ? JSON.parse(sessionStorage.getItem('goodsInfo')):[];
    },
    methods: {
      //获取支付状态
      getPayStu(num,pri){
        let _t= this;
        _t.$api.queryWinOrderStatus({orderNumber:num}).then(res => {
          if(res.data == 5){
            _t.$router.push({path:'/paySuccessQp',query:{pri:pri,orderNumber:num,orderId:this.$route.query.id}});
            _t.clearInterStu();
          }
        })
      },
      createPayInfo(orderNumber,totalSellPrice){
        let me = this
        if (this.paying) return
        this.paying = true
        let send = {
          payType:this.payArr[this.payIndex].value,
          orderNumber:orderNumber,
          amount:totalSellPrice,
        };
        this.$api.createPayInfo(send).then(res => {
          if(this.payArr[this.payIndex].name.indexOf('余额')> -1 ||this.payArr[this.payIndex].name.indexOf('授信')> -1 ){

          }else{
            this.toPay = true;
            this.creatQrCode(res.data);
          }
          this.stuInterval = window.setInterval(() => {
            this.getPayStu(orderNumber,totalSellPrice)
          }, 1000)
        }).catch( () => {
          me.paying= false
        })
      },

      format(dat){
        //获取年月日，时间
        var year = dat.getFullYear();
        var mon = (dat.getMonth()+1) < 10 ? "0"+(dat.getMonth()+1) : dat.getMonth()+1;
        var data = dat.getDate()  < 10 ? "0"+(dat.getDate()) : dat.getDate();
        var hour = dat.getHours()  < 10 ? "0"+(dat.getHours()) : dat.getHours();
        var min =  dat.getMinutes()  < 10 ? "0"+(dat.getMinutes()) : dat.getMinutes();
        var seon = dat.getSeconds() < 10 ? "0"+(dat.getSeconds()) : dat.getSeconds();

        return year +"-"+ mon +"-"+ data;
      },
      // 确定支付
      pay(){
        let _t= this;
        let totalSellPrice = this.payQpInfo.totalPrice*100;
        if(!this.orderNumber){
          delete this.saveInfo.totalPrice;
          this.saveInfo.reqOrderGroupDetails.map(item => {
            delete item.sellPrice
          });
          this.saveInfo.bookUseDate = this.format(new Date());
          this.saveInfo.orderType = 3;
          _t.$api.createOrderQp(this.saveInfo).then(res => {
            this.orderNumber = res.data;
            this.payMoney(res.data,totalSellPrice)
          })
        }else{
          this.payMoney(this.orderNumber,totalSellPrice)
        }
      },
      payMoney(num,totalSellPrice) {
        if(this.payIndex < 0) return this.$message.error('请选择支付方式');
        if(this.payArr[this.payIndex].name.indexOf('余额')> -1){
          if(!this.balance) return this.$message.error('可用余额不足，请选择其他支付方式');
        }
        if(this.payArr[this.payIndex].name.indexOf('授信')> -1){
          if(!this.creditBalance) return this.$message.error('可用授信余额不足，请选择其他支付方式');
        }
        this.createPayInfo(num,totalSellPrice)
      },
      creatQrCode(url) {
        document.getElementById("qrcode").innerHTML = "";
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 300,
          height: 300,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      },
      //获取服务器时间
      getSysTime(){
        this.time = '';
        this.$api.getSysTime().then(res => {
          var u = navigator.userAgent;
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1; //ios终端
          let endtime = '';
          if(isiOS){
            endtime = new Date(this.pTime.replace(/-/g,'/')).getTime()+3600000;
          }else{
            endtime = new Date(this.pTime).getTime()+3600000;
          }
          // endtime = new Date(this.pTime).getTime()+3600000;
          var nowtime = Number(res.data)*1000;
          // var nowtime = new Date().getTime();
          this.showTimeC = endtime-nowtime;
          this.getTime()
        })
      },
      getTime(){
        let _t = this;
        _t.interVal = null;
        _t.interVal = window.setInterval (function () {
          _t.showTime();
        }, 1000);
      },
      showTime(){
        let _t = this;
        _t.showTimeC = _t.showTimeC -1000;
        var  leftm = Math.floor(_t.showTimeC /(1000*60)%60),  //计算分钟数
          lefts = Math.floor(_t.showTimeC /1000%60);  //计算秒数
        leftm = leftm>9 ? leftm : '0'+leftm;
        lefts = lefts>9 ? lefts : '0'+lefts;
        if(_t.showTimeC > 0){
          _t.time =  leftm + "分" + lefts + "秒";  //返回倒计时的字符串
        }else{
          _t.clearInter();
          _t.time =  "已超时，订单自动关闭" ;  //返回倒计时的字符串

          return ''
        }
      },
      clearInter(){
        let _t = this;
        window.clearInterval(_t.interVal);
        _t.interVal = null;
        _t.time = '';
      },
      clearInterStu(){
        let _t = this;
        window.clearInterval(_t.stuInterval);
        _t.stuInterval = null;
      },
      getInfo(){
        this.payQpInfo = JSON.parse(sessionStorage.getItem('payQpInfo'));
        this.saveInfo = JSON.parse(sessionStorage.getItem('saveInfo'));
        this.payQpInfo.reqOrderGroupDetails.map(item => {
          item.totalNum = 1;
          item.reqOrderDetails.map(itm => {
            if(itm.startTime && itm.endTime){
              this.showTimeInTo = itm.startTime +' - '+ itm.endTime
            }
          })
        })
        let arr = JSON.parse(JSON.stringify(this.payQpInfo.reqOrderGroupDetails));
        for(let i = 0;i<arr.length;i++){
          arr[i].total = 1;
          for(let j = i+1;j<arr.length;j++){
            if(arr[i].groupCommodityId == arr[j].groupCommodityId){
              arr[i].total ++
              arr.splice(j,1)
              j--
            }
          }
        }
        this.showArr = JSON.parse(JSON.stringify(arr));
        this.pTime = new Date().getTime();
        this.getSysTime();

      },
      // 取消提交
      cancel() {
        this.$router.go(-1)
      },
      //查询账户余额
      getBusinessWallet(){
        this.$api.getBusinessWallet().then(res => {
          this.balance = res.data.balance;
          this.creditBalance = res.data.creditBalance;
        })
      },
      //支付方式
      queryDisPayType(){
        this.$api.queryDisPayType().then(res => {
          this.payArr = res.data;
        })
      },
    },
    onBeforeRouteLeave(to, from, next) {
      this.clearInter();
      this.clearInterStu();
      next()
    },
  };
</script>

<style scoped>
  @import "../assets/css/step.css";
  @import "../assets/css/submitOrder.css";
</style>
<style scoped lang="scss">
  :v-deep .qrbox img{
    margin: 0 auto;
  }
</style>
